//
//
//
//
//
//
//
//
//
import BaseImage from '@/components/base/BaseImage';
export default {
  components: {
    BaseImage
  },

  data() {
    return {
      heicSrc: "https://avatar-winona-staging.s3.us-west-2.amazonaws.com/6a5f3608-6a1a-4326-8582-e26074be9ac2/avatar/image_thumbnail.image?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAV7DTVAD24YFOBEXI%2F20240411%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20240411T151815Z&X-Amz-Expires=604800&X-Amz-Signature=db99fb44b445165422c037994872475edc073daf34086b75027d378caabaffd7&X-Amz-SignedHeaders=host&x-id=GetObject"
    };
  }

};